import React from "react";
import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import axios from "axios";
import { ApiUrl } from "../../API/Api";
import MenuBar from "../../Components/MenuBar";
import SliderImage from "../../Components/SliderImage";
import AboutHome from "../../Components/AboutHome";
import HomeRooms from "../../Components/HomeRooms";
import OurFacilities from "../../Components/OurFacilities";
import AllDetails from "../../Components/AllDetails";
import { Latestproject } from "../../Components/Latestproject";
import { Youtube } from "../../Components/youtube";
import Footer from "../../Components/Footer";
import { RotatingSquare } from "react-loader-spinner";


const Home = () => {
  const [homedata, setHomedata] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        setHomedata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <RotatingSquare
            visible={true}
            height="100"
            width="100"
            color="#012c6d"
            ariaLabel="uac-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />

        </div>
      )}
      {!isLoading && (
        <>
          <Header />
          <MenuBar menudata={homedata?.headermenudata} />
          <SliderImage sliderdata={homedata?.SlidesData} />
          <AboutHome />
          <HomeRooms projectdata={homedata?.projectdata} />
          <OurFacilities />
          <AllDetails testimonials={homedata?.testmonialdata} />
          <Latestproject projectdata={homedata?.newslettersdata} />
          <Youtube />
          <Footer />
        </>
      )}
    </>
  );
}

export default Home;
