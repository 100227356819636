import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Components/Header.jsx";
import MainFooter from "./partials/MainFooter";
import MainMenuBar from "./partials/MainMenubar";
import { RotatingSquare } from "react-loader-spinner";
import { ApiUrl } from "../API/Api.js";
import axios from "axios";

const MainLayout = () => {
  const [loading, setLoading] = useState(true);
  const [Homedata, setHomedata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedData = localStorage.getItem("HomeData");
        if (storedData) {
          setHomedata(JSON.parse(storedData));
        } else {
          const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
          localStorage.setItem("HomeData", JSON.stringify(response?.data?.data));
          setHomedata(response?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <RotatingSquare
          visible={true}
          height="100"
          width="100"
          color="#012c6d"
          ariaLabel="uac-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <>
      <Header />
      <MainMenuBar menudata={Homedata?.headermenudata} />
      <div style={{ minHeight: "66.5vh", padding: "10px" }}>
        <Outlet />
      </div>
      <MainFooter />
    </>
  );
};

export default MainLayout;
