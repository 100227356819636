import { useEffect, useState } from "react";

const SliderImage = ({ sliderdata }) => {
  const [sliderHeight, setSliderHeight] = useState(580);

  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      const newSliderHeight = windowHeight * 0.8;
      setSliderHeight(newSliderHeight);
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="banner" id="banner1">
        <div
          id="carouselExampleFade"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel">
          <div className="carousel-inner">
            {sliderdata?.map((item, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}>
                <img
                  className="d-block w-100 img-fluid"
                  src={item.image}
                  alt={`banner-${index}`}
                  style={{ height: `${sliderHeight}px`, maxHeight: "100%" }}
                  id="HomeSlider"
                />
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SliderImage;
